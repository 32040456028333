export const guidelineList = [
  {
    header: "Use the appropriate registered trademark symbol (®) and a capital “A.”",
    left: [
      "AMARILLO® or Amarillo®",
    ],
    right: [
      "AMARILLO or amarillo",
    ],
  },
  {
    header: "Use the AMARILLO® trademark as an adjective and not as a noun.",
    left: [
      "AMARILLO® brand VGXP01 varietal hops",
      "AMARILLO® VGXP01 hops",
      "Amarillo® hops",
    ],
    right: [
      "Made with Amarillo",
      "Amarillo adds notes of citrus",
      "We use Amarillo in our recipe",
    ],
  },
  {
    header: "Use the AMARILLO mark to refer to AMARILLO® hops as an ingredient.",
    left: [
      "brewed with AMARILLO® brand VGXP01 varietal hops",
      "contains Centennial and Amarillo® hops",
    ],
    right: [
      "brewed with Citra and Amarillo",
      "Hops: Amarillo",
    ],
  },
  {
    header: "Do not use the AMARILLO mark in a beer or hop product name without permission from VGF. " +
      "This use incorrectly implies some relationship to or endorsement by VGF.",
    left: [
      "The name “XYZ PALE ALE” with an ingredient list elsewhere on the label that includes AMARILLO® hops",
    ],
    right: [
      "AMARILLO PALE ALE",
      "ZAMARILLO IPA",
      "ONE HOP AMARILLO",
    ],
  },
  {
    header: "Include the following attribution of VGF’s ownership of the AMARILLO® trademark on your website: " +
      "AMARILLO® is a trademark owned by Virgil Gamache Farms, Inc.",
  },
  {
    header: "And where commercially practicable, also include the above attribution on labeling and packaging.",
  },
];
