import * as React from "react";
import environment from "../../../environment/environment";
// @ts-ignore
import hopImage from "../../images/Hop.png";
// @ts-ignore
import classes from "./BrandingForm.module.css";

const RECAPTCHA_KEY = environment.SITE_RECAPTCHA_KEY;

const BrandingForm = () => {
  return (
    <section className={classes.sectionBrandingForm}>
      <div className={classes.bugContainerBranding}>
        <div className={classes.movingBugBranding}>
          <img className={classes.bugImage} src={hopImage} />
        </div>
      </div>
      <div className={classes.formContainerBranding}>
        <div className={classes.headerContainer}>
          <h2 className={classes.amarilloHopBranding}>Amarillo hop branding</h2>
          <h2 className={classes.requestMarketingAssets}>
            Request Marketing Assets
          </h2>
        </div>
        <form
          action={"/thank-you"}
          name="Branding Form"
          method="post"
          data-netlify="true"
          netlify-honeypot="bot-field"
          className={classes.formBranding}
        >
          <input type="hidden" name="form-name" value="Branding Form" />
          <input type="hidden" name="bot-field" />
          <input
            className={classes.formBrandingInput}
            type="text"
            name="name"
            placeholder="NAME"
            required
          />
          <input
            className={classes.formBrandingInput}
            type="email"
            name="email"
            placeholder="EMAIL"
            required
          />
          {/* form-branding-textarea" */}
          <textarea
            className={classes.formBrandingInput}
            // @ts-ignore
            rows="5"
            name="request"
            placeholder="Description or Request"
            required
          />
          <button className={classes.formBrandingButton} type="submit">
            Request amarillo assets
          </button>
        </form>
      </div>
    </section>
  );
};

export default BrandingForm;
