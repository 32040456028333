import * as React from "react";
import { Link } from "gatsby";
import GuidelineItem from "./components/GuidelineItem/GuidelineItem";
import { guidelineList } from "./Branding.items";
import BrandingForm from "../Form/BrandingForm";
// @ts-ignore
import classes from "./Branding.module.css";

const Branding = () => {
  return (
    <div>
      <div className={classes.sectionWrapperFirst}>
        <div className={classes.guidelineHeaderContainer}>
          <h4 className={classes.guidelineHeader}>Branding Guidelines for VGF's AMARILLO Trademark</h4>
        </div>
        <div className={classes.brandingGuidelinesContainer}>
          <h2 className={classes.brandingGuidelines}>
            These Branding Guidelines may be updated from time to time, with the most recent version available at&nbsp;
            <Link className={classes.amarilloUrl} to="https://amarillohop.com/brand">
              https://amarillohop.com/brand. </Link>
            Please follow these guidelines (including as updated) when using the AMARILLO® trademark:
          </h2>
        </div>
        <div className={classes.guidelineItemContainer}>
          {guidelineList
            .map((item) => {
              return (
                <GuidelineItem header={item.header} left={item.left} right={item.right} />
              );
            })}
        </div>
        <BrandingForm />
      </div>
    </div>
  );
};

export default Branding;
