import * as React from "react";
// @ts-ignore
import classes from "./GuidelineItem.module.css";

const GuidelineItem = ({ header, left, right }: any) => {

  return (
    <div className={classes.guidelineItem}>
      <div>
        <h2 className={classes.headerGuideline}>{header}</h2>
      </div>
      {
        left ?
          <div className={classes.childrenGuideline}>
            <div className={classes.sideItem}>
              <h2 className={classes.proper}>Proper:</h2>
              {left.map((row: string) => (<h2 className={classes.properChild}>{row}</h2>))}
            </div>
            <div className={classes.sideItem}>
              <h2 className={classes.improper}>Improper:</h2>
              {right.map((row: string) => (<h2 className={classes.improperChild}>{row}</h2>))}
            </div>
          </div> : <></>
      }
    </div>
  );
};

export default GuidelineItem;
