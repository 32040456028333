import "../css/styles.css";

import * as React from "react";

import { LayoutProps, withLayout } from "../components/Layout";

import Branding from "../components/Branding/Branding";
import { SEOPagesDescription } from "../utils/seo.util";
import Seo from "../components/SEO/Seo";

const BrandPage = (props: LayoutProps) => {
  return (
    <React.Fragment>
      <Seo {...SEOPagesDescription.brand} />
      <Branding />
    </React.Fragment>
  );
};

export default withLayout(BrandPage);
